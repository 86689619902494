import { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import './ethicalGuidelinesForm.css';
import { axios } from "services/api/Config";
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { stylesFilledEthicalGuidelinesForm } from 'views/FilledEthicalGuidelinesForm/stylesFilledEthicalGuidelinesForm';
import FilledEthicalGuidelinesForm from 'views/FilledEthicalGuidelinesForm';

function EthicalGuidelinesForm() {
    
    const [data, setData] = useState(null);
    const [accepted, setAccepted] = useState(false)
    const [sending, setSending] = useState(false);
    const [signed, setSigned] = useState(false);
    const { token } = useParams();
    const history = useHistory();

    useEffect(() => {
        const fetchTokenPayload = async () => {
            try {
                const response = await axios.get("ethical-guidelines-token",  { params: { token } });
                console.log(response)
                const payload  = response.data;
                if (!payload) {
                    alert('Invalid token for sign the form, please contact the school.');
                    history.push('/login');
                } 
                setData(payload);
            } catch(error) {
                alert('Invalid token, please contact the school.');
                history.push('/login');
            }
        };
    
        if (token) {
          fetchTokenPayload();
        }
      }, []);


    function getCurrentDate() {
        const currentDate = new Date();
        const day = String(currentDate.getDate()).padStart(2, '0');
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = currentDate.getFullYear();
        
        return `${month}/${day}/${year}`;
    }

    function handleSign() {
        setSigned(!signed);

        const confirmForm = document.getElementById("submitFormContainer");
        confirmForm.scrollIntoView({ behavior: 'smooth' });
    }

    function generateEthicalFormPdfHtml(data) {
        const cssContent = stylesFilledEthicalGuidelinesForm;
        const renderedHtml = ReactDOMServer.renderToString(<FilledEthicalGuidelinesForm data={data} />);
        
        return `
            <!DOCTYPE html>
            <html lang="en">
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <link rel="preconnect" href="https://fonts.googleapis.com">
                    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                    <link href="https://fonts.googleapis.com/css2?family=Yellowtail&display=swap" rel="stylesheet">
                    <title>Ethical Guidelines Form</title>
                    <style>${cssContent}</style>
                </head>
            <body>
            <div class="container">
                ${renderedHtml}
            </div>
            </body>
            </html>
        `;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!accepted) {
            alert('Please accept the terms before submitting.');
            return;
        }

        if (!signed) {
            alert("Please ensure you sign the signature field.");
            return;
        }

        setSending(true);
        try {
            const html = generateEthicalFormPdfHtml(data);
            const body = {
                agentId: data.agentId,
                html
            }

            await axios.post("ethical-guidelines-signature", body);
        } catch (error) {
            console.error('Error sending request:', error);
        } finally {
            setSending(false);
            history.push('/signed-form');
        }
    }

    const handleChange = () => {
        setAccepted(!accepted);
    }

    return (
    <div className="agentFormContainer">
        { data ? (
            <>
            <div className="form">
                <div className='formContent'>
                    <h1>Third-Party Recruitment Agent Ethical Guidelines Form</h1>
                    <p>As a third-party recruitment agent representing <strong>Language Systems International (LSI)</strong>, please review and sign this Ethical
                        Guidelines Form. This document confirms your understanding and acceptance of the standards of <strong>honesty, transparency, and
                        accuracy</strong> in your recruitment activities for LSI.
                    </p>
                    <h2 className='topics'>1. Honesty and Accuracy in Representation</h2>
                    <ul>
                        <li>
                            All promotional materials, brochures, and descriptions provided to prospective students must accurately reflect LSI’s
                            <strong> programs, costs, and application processes.</strong>
                        </li>
                        <li>
                            You must not misrepresent LSI’s services, student expectations, or visa and enrollment requirements.
                        </li>
                    </ul>
                    <h2 className='topics'>2. Transparency in Communication</h2>
                    <ul>
                        <li>
                            You will provide students with clear, complete, and truthful information about LSI’s 
                            <strong> programs, fees, visa procedures</strong>, and any other relevant details.
                        </li>
                        <li>
                            You will inform students of the <strong>non-refundable nature</strong> of certain fees (as detailed in the LSI agent agreement) and any
                            additional costs associated with their studies.
                        </li>
                    </ul>
                    <h2 className='topics'>3. Commitment to Student Interests</h2>
                    <ul>
                        <li>
                            You will work in the best interests of prospective students, ensuring they are fully informed of the <strong>terms and conditions</strong>
                            of their enrollment.
                        </li>
                        <li>
                            You will not advise students to engage in any activity that violates <strong>visa regulations or other applicable laws.</strong> 
                        </li>
                    </ul>
                    <h2 className='topics'>4. Compliance with Legal Standards</h2>
                    <ul>
                        <li>
                            You agree to operate in accordance with the local and national laws of the countries where you conduct business, as well
                            as <strong>U.S. immigration and visa regulations.</strong>
                        </li>
                        <li>
                            You will not assist any student in circumventing immigration rules or providing false information.
                        </li>
                    </ul>
                    <h2 className='topics'>5. Professional Conduct</h2>
                    <ul>
                        <li>
                            You are committed to maintaining the highest standards of <strong>professionalism</strong> in all interactions with prospective students
                            and LSI representatives.
                        </li>
                        <li>
                            Any advertising or promotional efforts will only use <strong>approved materials</strong> from LSI and will clearly indicate that you act
                            as an <strong>independent contractor</strong>, not as an LSI employee.
                        </li>
                    </ul>
                    <h2 className='topics'>Acknowledgment and Signature</h2>
                    <p>By signing this form, you confirm your understanding and commitment to the ethical guidelines outlined above.</p>
                    <div className='agentSignatureContainer'>
                        <div><strong>Agent Name: </strong><span>{data.agentName}</span><br></br></div>
                        <div><strong>Agent Company: </strong><span>{data.agentCompany}</span><br></br></div>
                        <div><strong>Date: </strong><span>{getCurrentDate()}</span><br></br></div>
                        <div><strong>Agent Signature: </strong>{}<button onClick={handleSign} className='agentSignatureLabel'>{signed ? data.agentName : ''}</button><br></br></div>
                    </div>
                </div>
            </div>
            <div id="submitFormContainer" className="submitFormContainer">
                <form onSubmit={handleSubmit}>
                    <label>
                        <input
                        type="checkbox"
                        checked={accepted}
                        onChange={handleChange}
                        />
                        I {data.agentName} accept the terms of the ethical guidelines form.
                    </label>
                    <button type="submit" disabled={!accepted || sending}>
                        {sending ? 'Sending...' : 'Sign Form'}
                    </button>
                </form>
            </div>
            
            </>
        ) : (
        <>
        </>)}
    </div>
    );
}
export default EthicalGuidelinesForm;
